import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import VueMeta from "vue-meta";
import { authService } from "@/helpers/api-services/auth.service";
import { pService } from "@/helpers/api-services/p.service";
import store from "@/state/store";
import i18n from "@/i18n";
import routes from "./routes";
import md5 from "md5";
import { GoogleTrackingService } from "@/helpers/tracking-services/google-tracking-service";
import { Position, PositionResult } from "vue-router/types/router";

const TEN_MIN = 10 * 60 * 1000;
const THIRTY_MIN = 30 * 60 * 1000;

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "page",
});

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.

  scrollBehavior(
    routeTo: Route,
    routeFrom: Route,
    savedPosition: void | Position
  ): PositionResult {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function clearStorage(): void {
  store.dispatch("pp/setProfilePP", null);
  store.dispatch("auth/setAuthUserID", 0);
  store.dispatch("auth/setAuthStatus", "inactive");
  store.dispatch("auth/setAuthFeatures", []);
  store.dispatch("auth/setAuthIndustryID", null);
}

// Before each route evaluates...
router.beforeEach(
  async (routeTo: Route, routeFrom: Route, next: NavigationGuardNext) => {
    // new code, gets meta variable from the route
    const featureRequired = routeTo.meta?.featureRequired;
    const pauseStatus = routeTo.meta?.pauseStatus;

    if (routeTo.params.lang) {
      i18n.locale = routeTo.params.lang.toLowerCase();
    } else {
      next({ path: "/en" + routeTo.path, query: routeTo.query });
      return;
    }
    if (routeTo.query.a && typeof routeTo.query.a === "string") {
      const a_pieces = routeTo.query.a.split("-");
      const hashed = md5(process.env.VUE_APP_MD5_HASH + a_pieces[0]);

      // Clear cache and go to the route without personalization if the 'a' query is an invalid pp-code.
      if (hashed !== a_pieces[1]) {
        clearStorage();
        next();
        return;
      }
      const resp = await authService
        .getAuth(a_pieces[0])
        .then(async (result) => {
          if (result.user && result.user.status !== "inactive") {
            store.dispatch("auth/setAuthFeatures", result.user.features);
            if (store.getters["auth/authIsFree"]) {
              store.dispatch("auth/setAuthFeatures", []);
              return "error";
            }

            store.dispatch("auth/setAuthUserID", result.user.id);
            store.dispatch("auth/setAuthStatus", result.user.status);

            store.dispatch(
              "auth/setAuthIndustryID",
              result.user.access_levels?.[0].industry
            );
            await pService.getPersonalization(result.user.id).then((result) => {
              store.dispatch("pp/setProfilePP", result);
            });

            // if features aren't required for the route, go to the route
            if (!featureRequired && !pauseStatus) {
              next();
              return;
            }

            // if the page requires video, check that the current member alias has video access, if so go to the route
            if (
              featureRequired === "video" &&
              store.getters["auth/authIsVideo"]
            ) {
              next();
              return;
            }

            // If the page is unaccessible to paused members and the member is not paused then go to the page.
            if (
              pauseStatus === false &&
              store.getters["auth/authStatusNotPaused"]
            ) {
              next();
              return;
            }

            // required a feature that the current member alias does not have access, fallback to default
            next({ name: "default" });
            return;
          } else {
            return "error";
          }
        });
      if (resp === "error") {
        // if inactive return blank white screen
        return false;
      }
    } else {
      // Local storage handling for urls without an 'a' query.
      if (store.getters["pp/profilePP"]) {
        if (Date.now() - store.getters["pp/profilePP"].loadedAt > THIRTY_MIN) {
          clearStorage();
        } else if (
          Date.now() - store.getters["pp/profilePP"].loadedAt >
          TEN_MIN
        ) {
          pService
            .getPersonalization(store.getters["auth/authUserID"])
            .then((result) => {
              store.dispatch("pp/setProfilePP", result);
            });
        }
      }
    }

    next();
  }
);

router.beforeResolve(
  async (routeTo: Route, routeFrom: Route, next: NavigationGuardNext) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
      // For each matched route...
      for (const route of routeTo.matched) {
        await new Promise<void>((resolve, reject) => {
          // If a `beforeResolve` hook is defined, call it with
          // the same arguments as the `beforeEnter` hook.
          if (route.meta && route.meta.beforeResolve) {
            route.meta.beforeResolve(
              routeTo,
              routeFrom,
              (...args: string[]) => {
                // If the user chose to redirect...
                if (args.length) {
                  // If redirecting to the same route we're coming from...
                  // Complete the redirect.
                  next(...args);
                  reject(new Error("Redirected"));
                } else {
                  resolve();
                }
              }
            );
          } else {
            // Otherwise, continue resolving the route.
            resolve();
          }
        });
      }
      // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
      return;
    }

    // If we reach this point, continue resolving the route.
    next();
  }
);

router.afterEach((routeTo) => {
  GoogleTrackingService.pageView({ to: routeTo });
});

export default router;
